import classNames from "classnames"
import * as React from "react"
import styled from "styled-components"
import { mediaQueries } from "styles/variables"

export type BlockTitlePropsT = {
  title: string
  className?: string
  align?: "left" | "center" | "right"
  mobileAlign?: "left" | "center" | "right"
  type?: "h1" | "h2" | "h3" | "h4"
  tag?: "h1" | "h2" | "h3" | "h4"
  textOverflowLines?: number
  marginBottom?: boolean
}

const StyledTitle = styled(({ component: Component, ...props }) => (
  <Component {...props} />
))`
  ${({ $type }) =>
    $type === "h1" &&
    `
    letter-spacing: -0.025em;
    font-size: 36px;
    ${mediaQueries.smMax} {
      line-height: 1.3;
    }
    ${mediaQueries.smMin} {
      font-size: 38px;
      line-height: 1.1;
    }
    ${mediaQueries.xlMin} {
      font-size: 54px;
    }
  `}

  ${({ $textOverflowLines }) =>
    $textOverflowLines &&
    `
    display: -webkit-box;
    -webkit-line-clamp: ${$textOverflowLines};
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  `}
`

const BlockTitle: React.ComponentType<BlockTitlePropsT> = ({
  type = "h2",
  title,
  className,
  align = "center",
  tag,
  textOverflowLines,
  mobileAlign,
  marginBottom = true,
}) => {
  const component = tag ? tag : type

  return (
    <StyledTitle
      className={classNames(
        "text-quantcomBlue font-bold xl:text-5xl sm:text-4xl text-2xl",
        className,
        {
          [`${mobileAlign ? "sm:" : ""}text-${align}`]: align,
          [`text-${mobileAlign}`]: mobileAlign,
          "xl:mb-20 sm:mb-14 mb-10": marginBottom,
        }
      )}
      component={component}
      $type={type}
      $textOverflowLines={textOverflowLines}
    >
      {title}
    </StyledTitle>
  )
}

export default BlockTitle
