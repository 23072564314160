const breakpoints = [576, 600, 768, 1024, 1280]

export const getImageFile = (id, format, formats) => {
  if (formats == null) {
    return "";
  }
  if (typeof formats[format] === "undefined") {
    return "/"
  }
  if (process.env.NODE_ENV === "development") {
    return formats[format].url
  }
  return "/files/" + formats[format].hash + formats[format].ext
}

export const getFile = file => {
  if (file == null || file.hash == null || file.ext == null){
    return "";
  }
  if (process.env.NODE_ENV === "development") {
    return file.url
  }
  return "/files/" + file.hash + file.ext
}

export const getImageBreakpoint = (format, originalWidth) => {
  switch (format) {
    case "desktop":
      return "(min-width: 1025px)"
    case "medium":
      return "(min-width: 576px)"
    case "original":
      const breakpoint = breakpoints.reduce((prev, curr) =>
        Math.abs(curr - originalWidth) < Math.abs(prev - originalWidth)
          ? curr
          : prev
      )
      return "(min-width: " + breakpoint + "px)"
    case "small":
    default:
      return "(max-width: 576px)"
  }
}
