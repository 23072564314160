import * as React from "react"
import { IconsId } from "assets/fonts/iconfont/icons"
import Icon from "components/atoms/Icon"

type Props = {
  title: string
  icon?: IconsId
}

const SupportCategoryTitle: React.ComponentType<Props> = ({ title, icon }) => {
  return (
    <div className={"bg-gray-100 px-8 py-6 flex items-center"}>
      {icon && (
        <div className={"items-center flex"}>
          <Icon icon={icon} color={"text-quantcomBlue"} size={"text-3xl"} />
        </div>
      )}

      <div className={"ml-2 text-quantcomBlue font-bold sm:text-4xl text-2xl"}>
        {title}
      </div>
    </div>
  )
}

export default SupportCategoryTitle
