import * as React from "react"
import SEO from "components/seo"
import { ClientsT, IconT, PageContextT } from "../types/Common"
import Layout from "../components/organisms/Layout"
import Container from "components/atoms/Container"
import styled from "styled-components"
import BlockTitle from "components/atoms/BlockTitle"
import { getIsSSR } from "utilities/ssr"
import axios from "axios"
import SupportCategoryTitle from "components/atoms/SupportCategoryTitle"
import Grid from "components/PageBuilder/Common/Grid"
import { ProductDetail } from "components/atoms/ProductDetail"
import LoadingContainer from "components/atoms/LoadingContainer"
import { useTranslation } from "react-i18next"
import Icon from "components/atoms/Icon"
import Box from "components/molecules/Box"
import { getFile } from "utilities/Image"
import { default as fetch } from "node-fetch"

type Props = {
  pageContext: PageContextT
  clients: ClientsT
  location: any
}

type SearchResult = {
  data: {
    products: SearchData[]
    pages: SearchData[]
    supportArticles: SearchData[]
    blogs: SearchData[]
    documents: SearchDocumentData[]
  }
}

type SearchData = {
  id: number
  name: string
  url: string
}

type TFile = {
  hash: string
  ext: string
  url: string
}

type SearchDocumentData = {
  id: number
  name: string
  file: TFile
}

const Title = styled(BlockTitle)`
  margin-bottom: 0.5em;
`
const IndexPage: React.ComponentType<Props> = (props: Props) => {
  const {
    location,
    pageContext: {
      mainHeaderLinks,
      otherHeaderLinks,
      navigationLinks,
      footerSections,
      socialLinks,
      commonLinks,
      l3,
      configurations,
      mobileHeaderLinks,
    },
  } = props

  const { i18n, t } = useTranslation()
  const params = new URLSearchParams(location.search)
  const search = params.get("search")
  const [loading, setLoading] = React.useState(false)
  const [products, setProducts] = React.useState<SearchData[] | []>([])
  const [pages, setPages] = React.useState<SearchData[] | []>([])
  const [blogs, setBlogs] = React.useState<SearchData[] | []>([])
  const [documents, setDocuments] = React.useState<SearchDocumentData[] | []>(
    []
  )
  const [supportArticles, setSupportArticles] = React.useState<
    SearchData[] | []
  >([])
  const isSSR = getIsSSR()

  function replaceSpecialCharactersInString(text) {
    const characterMap = {
      "á": "a",
      "ä": "a",
      "č": "c",
      "ç": "c",
      "ď": "d",
      "é": "e",
      "ě": "e",
      "ë": "e",
      "í": "i",
      "ĺ": "l",
      "ľ": "l",
      "ň": "n",
      "ó": "o",
      "ô": "o",
      "ö": "o",
      "ř": "r",
      "š": "s",
      "ť": "t",
      "ú": "u",
      "ů": "u",
      "ü": "u",
      "ý": "y",
      "ž": "z",
      "Á": "A",
      "Ä": "A",
      "Č": "C",
      "Ç": "C",
      "Ď": "D",
      "É": "E",
      "Ě": "E",
      "Ë": "E",
      "Í": "I",
      "Ĺ": "L",
      "Ľ": "L",
      "Ň": "N",
      "Ó": "O",
      "Ô": "O",
      "Ö": "O",
      "Ř": "R",
      "Š": "S",
      "Ť": "T",
      "Ú": "U",
      "Ů": "U",
      "Ü": "U",
      "Ý": "Y",
      "Ž": "Z"
    }

    let result = ""
    for (let i = 0; i < text.length; i++) {
      const searchElement = text.charAt(i)
      if (Object.keys(characterMap).includes(searchElement)) {
        result += characterMap[searchElement]
      } else {
        result += searchElement
      }
    }
    return result
  }

  async function fetchPosts(search: string) {
    setLoading(true)

    let index = window.__FLEXSEARCH__.en.index
    let store = window.__FLEXSEARCH__.en.store
    let results = []
    const searchWithoutSpecialCharacters = replaceSpecialCharactersInString(search);

    Object.keys(index).forEach(idx => {
      results.push(...index[idx].values.search(search))
      if (search != searchWithoutSpecialCharacters) {
        results.push(...index[idx].values.search(searchWithoutSpecialCharacters))
      }
    })
    results = Array.from(new Set(results))
    let nodes = store
      .filter(node => (results.includes(node.id) ? node : null))
      .map(node => node.node)

    // const data = (await axios.get(
    //   `${process.env.GATSBY_API_URL}/search/${search}/${
    //     i18n.language === "en" ? "en" : "cs-CZ"
    //   }`
    // )) as SearchResult

    const lang = i18n.language == "cs" ? "cs-CZ" : "en";
    const products = nodes
      .filter(node => {
        if (node.locale == lang) {
          return node
        }
      })
      .map(node => {
        let humanReadUrlTitle = "Bez názvu"
        if (node.title == null) {
          humanReadUrlTitle = node.url.split("/")
            .filter(part => {
              if (part) {
                return part
              }
            })
            .map((word) => {
              return word[0].toUpperCase() + word.substring(1)
            })
            .join(" - ")
        }

        return {
          id: node.id,
          name: node.title ?? humanReadUrlTitle,
          url: node.url,
          slug: node.url,
          description: node.perex,
          products: null,
          icon: null,
          ctaButtonText: null,
          infoBarText: null,
          showCtaButton: null,
          showInfoBar: null
        }
      })

    const filteredDocuments = props.pageContext.documents
      .filter(document => {
        const documentName = document.name.toLowerCase()
        if (documentName.includes(search.toLowerCase()) || documentName == search.toLowerCase() ||
          documentName.includes(searchWithoutSpecialCharacters.toLowerCase()) || documentName == searchWithoutSpecialCharacters.toLowerCase()) {
          return document
        }
      })
      .map(document => {
        return {
          name: document.name,
          file: document.file
        }
      })

    setPages(products)
    setBlogs([])
    setProducts([])
    setSupportArticles([])
    setDocuments(filteredDocuments)
    setLoading(false)
  }

  React.useEffect(() => {
    async function fetchData() {
      if (typeof search !== "undefined" && search !== "") {
        await fetchPosts(search)
      }
    }
    fetchData()
  }, [])

  return (
    <Layout
      mainHeaderLinks={mainHeaderLinks}
      otherHeaderLinks={otherHeaderLinks}
      navigationLinks={navigationLinks}
      footerSections={footerSections}
      socialLinks={socialLinks}
      commonLinks={commonLinks}
      l3={l3}
      configurations={configurations}
      mobileHeaderLinks={mobileHeaderLinks}
      breadCrumbs={[]}
      refetchSearch={fetchPosts}
    >
      <SEO title={"Vyhledávání"} description={t("searchResults")} />
      <Container className={"sm:mt-20 mt-16 mb-20"} mobileNoPadding>
        <Title
          title={t("searchResults") + " \"" + search + "\""}
          align={"left"}
          className={"sm:px-0 px-4"}
        />
        {/* <button
          onClick={() => axios.get(`${process.env.GATSBY_API_URL}/reindex`)}
        >
          Klikni pre reindex
        </button> */}
        {(typeof search === "undefined" || search === "") && (
          <p>{t("noResults")}</p>
        )}
        {loading && (
          <Container>
            <LoadingContainer />
          </Container>
        )}
        {!loading && (
          <>
            {products.length > 0 && (
              <div className={"my-10"}>
                <SupportCategoryTitle title={t("products")} />
                <div className={"m-10"}>
                  <Grid xs={1} sm={2} lg={3}>
                    {products.map(article => {
                      return (
                        <ProductDetail
                          key={article.id}
                          link={article.url}
                          buttonTitle={t("findOutMore")}
                          product={article}
                        />
                      )
                    })}
                  </Grid>
                </div>
              </div>
            )}
            {pages.length > 0 && (
              <div className={"my-10"}>
                {/*<SupportCategoryTitle title={t("pages")} />*/}
                <div className={"m-10"}>
                  <Grid xs={1} sm={2} lg={3}>
                    {pages.map(article => {
                      return (
                        <ProductDetail
                          key={article.id}
                          link={article.url}
                          buttonTitle={t("findOutMore")}
                          product={article}
                        />
                      )
                    })}
                  </Grid>
                </div>
              </div>
            )}
            {supportArticles.length > 0 && (
              <div className={"my-10"}>
                <SupportCategoryTitle title={t("support")} />
                <div className={"m-10"}>
                  <Grid xs={1} sm={2} lg={3}>
                    {supportArticles.map(article => {
                      return (
                        <ProductDetail
                          key={article.id}
                          link={article.url}
                          buttonTitle={t("findOutMore")}
                          product={article}
                        />
                      )
                    })}
                  </Grid>
                </div>
              </div>
            )}
            {blogs?.length > 0 && (
              <div className={"my-10"}>
                <SupportCategoryTitle title={t("blogs")} />
                <div className={"m-10"}>
                  <Grid xs={1} sm={2} lg={3}>
                    {blogs.map(blog => {
                      return (
                        <ProductDetail
                          key={blog.id}
                          link={
                            i18n.language === "en"
                              ? `/en/blog/${blog.slug}`
                              : `/blog/${blog.slug}`
                          }
                          buttonTitle={t("findOutMore")}
                          product={blog}
                        />
                      )
                    })}
                  </Grid>
                </div>
              </div>
            )}
            {documents?.length > 0 && (
              <div className={"my-10"}>
                <SupportCategoryTitle title={t("documents")} />
                <div className={"m-10"}>
                  <Grid xs={1} sm={2} lg={3}>
                    {documents.map(doc => {
                      return (
                        <Box
                          link={getFile(doc.file)}
                          outline
                          // buttonTitle={t("findOutMore")}
                          icon="download"
                        >
                          <div
                            className={"flex items-center group sm:mb-0 mb-10"}
                          >
                            <Icon
                              icon={"pdf"}
                              color={"text-quantcomBlue"}
                              size="text-2xl"
                            />
                            <h3
                              className={
                                "pl-4 text-quantcomDarkBlue font-black text-2xl"
                              }
                            >
                              {doc.name}
                            </h3>
                          </div>
                        </Box>
                      )
                    })}
                  </Grid>
                </div>
              </div>
            )}
          </>
        )}
        {!loading &&
          pages.length === 0 &&
          products.length === 0 &&
          supportArticles.length === 0 &&
          blogs?.length === 0 &&
          documents?.length === 0 && <p>{t("noResults")}</p>}
      </Container>
    </Layout>
  )
}

export default IndexPage
